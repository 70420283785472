import {
  ApolloClient,
  ApolloLink,
  concat,
  DefaultOptions,
  split,
} from '@apollo/client';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { ServerError } from '@apollo/client/link/utils';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Store } from '@reduxjs/toolkit';
import { createUploadLink } from 'apollo-upload-client';

import { cache } from 'api/cache';
import { getEnvironment } from 'api/environment';
import { getToken, logoutStart } from 'redux/auth';
import { sentry } from 'utils';

const apiEnvironment = getEnvironment();

const uploadHttpLink = createUploadLink({ uri: `${apiEnvironment.apiUrl}` });

const wsLink = (store: Store) =>
  new WebSocketLink({
    uri: apiEnvironment.wssUrl || '',
    options: {
      reconnect: true,
      reconnectionAttempts: 5,
      lazy: true,
      connectionParams: () => {
        const token = getToken(store.getState());
        if (token) {
          return {
            Authorization: `jwt ${token}`,
          };
        }
        return {};
      },
    },
  });

// Inject the authentication token into the requests made by apollo
const authMiddleware = (store: Store) =>
  new ApolloLink((operation, forward) => {
    const token = getToken(store.getState());
    if (token) {
      operation.setContext({
        headers: {
          authorization: `jwt ${token}`,
        },
      });
    } else {
      operation.setContext({
        headers: {},
      });
    }

    return forward(operation);
  });

// Checks the incoming error from the Apollo client and if it's a 401 attempts
// to refresh the token
const errorHandler = (reduxStore: Store) => {
  return onError((err: ErrorResponse) => {
    const { networkError, operation, graphQLErrors } = err;

    // Notify via sentry if something breaks
    if (networkError?.message !== 'Network request failed') {
      sentry.withScope((scope) => {
        scope.setExtra('exception', err);
        scope.setExtra('operation', operation);
        scope.setExtra('graphQLErrors', graphQLErrors);

        const opName = operation.operationName
          ? operation.operationName
          : 'unknown operation';

        sentry.captureMessage(
          `Apollo error: ${opName}`,
          sentry.Severity.Warning
        );
      });
    }

    if ((networkError as ServerError)?.statusCode === 401) {
      // Don't import the logout action since that will cause a circular
      // dependency
      reduxStore.dispatch({ type: logoutStart.type });
    }
  });
};

const retryLink = new RetryLink({
  attempts: { max: 2 },
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'none',
  },
  mutate: {
    errorPolicy: 'none',
  },
};

const splitLink = (store: Store) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink(store),
    uploadHttpLink as unknown as ApolloLink
  );

// eslint-disable-next-line import/no-mutable-exports
export let apolloClient: ApolloClient<any>;

export const setLink = (reduxStore: Store) => {
  apolloClient.setLink(
    retryLink.concat(
      errorHandler(reduxStore).concat(
        concat(authMiddleware(reduxStore), splitLink(reduxStore))
      )
    )
  );
};

export const factory = (reduxStore: Store) => {
  apolloClient = new ApolloClient({
    cache,
    defaultOptions,
  });

  setLink(reduxStore);

  return apolloClient;
};
