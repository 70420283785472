import { isDemo, isLocalhost, isStaging, isTest } from 'utils';

export type Environment = {
  apiUrl: string;
  wssUrl: string;
};

export const getEnvironment = (): Environment => {
  if (typeof process.env.REACT_APP_API_URL === 'string') {
    return {
      apiUrl: process.env.REACT_APP_API_URL,
      wssUrl: 'ws://localhost:4000/graphql',
    };
  }

  if (isLocalhost()) {
    return {
      apiUrl: 'http://localhost:4000/graphql',
      wssUrl: 'ws://localhost:4000/graphql',
    };
  }

  if (isTest()) {
    return {
      apiUrl: 'https://api-demo.payhour.isdemo.se/graphql',
      wssUrl: 'wss://api-demo.payhour.isdemo.se/graphql',
    };
  }

  if (isDemo() || isStaging()) {
    return {
      apiUrl: 'https://api.payhour.isdemo.se/graphql',
      wssUrl: 'wss://api.payhour.isdemo.se/graphql',
    };
  }

  return {
    apiUrl: 'https://api2.payhour.se/graphql',
    wssUrl: 'wss://api2.payhour.se/graphql',
  };
};
