import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { apolloClient, setLink } from 'api/apollo';
import { useMeQuery, User } from 'api/graphql';
import { Router, SEO } from 'components/tools';
import { useAuth } from 'hooks';
import { setUser } from 'redux/auth';
import { store } from 'redux/store';

export const App = () => {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const { data } = useMeQuery({
    errorPolicy: 'all',
  });

  useEffect(() => {
    const user = data?.me as User;
    if (user) dispatch(setUser({ user }));
  }, [dispatch, data]);

  useEffect(() => {
    apolloClient.resetStore();
    setLink(store);
  }, [token]);

  return (
    <>
      <SEO titleTemplate="%s | Galo" defaultTitle="Galo" />
      <Router />
    </>
  );
};
