// https://github.com/willnorris/imageproxy

import { isDemo, isLocalhost, isStaging, isTest } from 'utils';

export const imageProxy = ({
  options = [],
  url,
}: {
  options?: string[];
  url: string;
}) => {
  const path = `${options.join(',')}/${url}`;

  if (isTest()) {
    return `https://proxy-demo.payhour.isdemo.se/${path}`;
  }

  if (isLocalhost() || isDemo() || isStaging()) {
    return `https://proxy.payhour.isdemo.se/${path}`;
  }

  return `https://proxy.payhour.se/${path}`;
};
