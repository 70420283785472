import { useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ChannelOrigin,
  CustomChannelsQuery,
  useCustomChannelsLazyQuery,
  UserRole,
} from 'api/graphql';
import { EmptyInboxAndErrorIcon, NoSearchResultsIcon } from 'assets/icons';
import { Channel } from 'components/ui/chat';
import { Select, Text } from 'components/ui/forms';
import { Button, Empty, Grid, Gutter, Spinner } from 'components/ui/general';
import { DefaultFilterChannels } from 'consts/chat';
import { Paths } from 'consts/router';
import { useAuth } from 'hooks';
import { format } from 'utils';

import { PeerToPeer } from './subComponent/PeerToPeer';

import { texts } from './Channels.text';

import styles from './Channels.module.scss';

export const Channels = () => {
  const { user, role } = useAuth();
  const { formatMessage } = useIntl();
  const { register, control, watch } = useForm();

  // 'subscribeToMore' and 'fetchMore' is taking place in
  // src/components/ui/general/Header/subcomponents/Chat.tsx
  const [fetchChannels, { data, loading, error }] =
    useCustomChannelsLazyQuery();

  const watchSearchTerm = watch('searchTerm');
  const watchChannelOrigin = watch('channelOrigin');
  const isOneToOneFilter = watchChannelOrigin?.value === ChannelOrigin.OneToOne;

  useEffect(() => {
    fetchChannels({
      variables: {
        filter: {
          userId: user?.id,
          limit: DefaultFilterChannels.Limit,
        },
      },
    });
  }, [fetchChannels, isOneToOneFilter, user?.id]);

  const getSearchTermResults = useCallback(
    (channelsEdges: CustomChannelsQuery['channels']['edges']) => {
      const lowerCaseValue = watchSearchTerm.toLowerCase();
      const filterChannels = channelsEdges.filter(({ name, origin }) => {
        switch (origin) {
          case ChannelOrigin.Group:
          case ChannelOrigin.Broadcast:
          case ChannelOrigin.System:
            return name?.toLowerCase()?.includes(lowerCaseValue) || false;

          default:
            return false;
        }
      });

      return filterChannels;
    },
    [watchSearchTerm]
  );

  const getChannelOriginResults = useCallback(
    (channelsEdges: CustomChannelsQuery['channels']['edges']) => {
      const filterChannels = channelsEdges.filter(
        ({ origin }) => origin === (watchChannelOrigin.value as ChannelOrigin)
      );

      return filterChannels;
    },
    [watchChannelOrigin]
  );

  // Not passing variables to backend because it will interfere with the cache
  // logic from src/components/ui/general/Header/subcomponents/Chat.tsx
  const getFilteredChannelsEdges = useMemo<
    CustomChannelsQuery['channels']['edges'] | null
  >(() => {
    let channelsEdges = data?.channels.edges || null;

    if (
      !channelsEdges?.length ||
      (!watchSearchTerm?.length && !watchChannelOrigin)
    ) {
      channelsEdges = null;
    }

    if (watchSearchTerm?.length && channelsEdges) {
      channelsEdges = getSearchTermResults(channelsEdges);
    }

    if (watchChannelOrigin && channelsEdges) {
      channelsEdges = getChannelOriginResults(channelsEdges);
    }

    return channelsEdges;
  }, [
    data?.channels.edges,
    getChannelOriginResults,
    getSearchTermResults,
    watchChannelOrigin,
    watchSearchTerm,
  ]);

  const renderContent = useCallback(() => {
    if (loading) {
      return (
        <div className={styles.loading}>
          <Spinner
            visible
            text={<FormattedMessage {...texts.fetchChannelsLoading} />}
            color="primary"
          />
        </div>
      );
    }

    if (error) {
      return (
        <div className={styles.error}>
          <Empty
            title={<FormattedMessage {...texts.fetchChannelsError} />}
            message={error.message}
          />
        </div>
      );
    }

    if (data?.channels.edges.length && getFilteredChannelsEdges?.length !== 0) {
      const { edges, meta } = data.channels;

      // Little risky if the error occurs in
      // src/components/ui/general/Header/subcomponents/Chat.tsx
      // but I'll take it for now
      if (edges.length < meta.total && !isOneToOneFilter) {
        return (
          <div className={styles.loading}>
            <Spinner
              visible
              text={<FormattedMessage {...texts.fetchChannelsLoading} />}
              color="primary"
            />
          </div>
        );
      }

      const collectChannels = getFilteredChannelsEdges?.length
        ? getFilteredChannelsEdges
        : edges;

      return (
        <div className={styles.channels}>
          {[...collectChannels]
            .sort((a, b) =>
              format
                .date({ date: b.lastSentAt })
                .diff(format.date({ date: a.lastSentAt }))
            )
            .map((channel) => (
              <Channel
                key={channel.id}
                channel={channel}
                isOneToOneFilter={isOneToOneFilter}
              />
            ))}
        </div>
      );
    }

    if (getFilteredChannelsEdges?.length === 0) {
      return (
        <div className={styles.empty}>
          <Empty
            title={<FormattedMessage {...texts.filterResultsEmpty} />}
            icon={<NoSearchResultsIcon />}
          />
        </div>
      );
    }

    return (
      <div className={styles.empty}>
        <Empty
          title={<FormattedMessage {...texts.fetchChannelsEmpty} />}
          icon={<EmptyInboxAndErrorIcon />}
        />
      </div>
    );
  }, [
    data?.channels,
    error,
    getFilteredChannelsEdges,
    isOneToOneFilter,
    loading,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <Gutter gutter={{ bottom: 3 }}>
          <Gutter.Item>
            <Grid align="middle" gutter={{ left: 1, bottom: 1 }}>
              <Grid.Item fill>
                <h6 className={styles.topHeading}>
                  <FormattedMessage {...texts.topHeading} />
                </h6>
              </Grid.Item>
              {role === UserRole.Admin && (
                <Grid.Item>
                  <Button
                    ghost
                    to={Paths.ChatBroadcasts}
                    iconRight={{ name: 'tower-broadcast' }}
                  >
                    <FormattedMessage {...texts.buttonChatBroadcasts} />
                  </Button>
                </Grid.Item>
              )}
              <Grid.Item>
                <Button
                  ghost
                  to={Paths.ChatGroups}
                  iconRight={{ name: 'users' }}
                >
                  <FormattedMessage {...texts.buttonChatGroups} />
                </Button>
              </Grid.Item>
            </Grid>
          </Gutter.Item>
          <Gutter.Item>
            <Gutter gutter={{ bottom: 1 }}>
              <Gutter.Item>
                <Text
                  register={register}
                  name="searchTerm"
                  fullWidth
                  iconLeft={{ name: 'search' }}
                  placeholder={formatMessage(texts.searchTermPlaceholder)}
                />
              </Gutter.Item>
              {role === UserRole.Admin && (
                <Gutter.Item>
                  <Select
                    control={control}
                    isClearable
                    options={Object.values(ChannelOrigin)
                      .filter((origin) => origin !== ChannelOrigin.Public)
                      .map((origin) => ({
                        label: formatMessage(texts[`channelOrigin_${origin}`]),
                        value: origin,
                      }))}
                    name="channelOrigin"
                    fullWidth
                    placeholder={formatMessage(texts.channelOriginPlaceholder)}
                  />
                </Gutter.Item>
              )}
            </Gutter>
          </Gutter.Item>
        </Gutter>
      </div>
      {isOneToOneFilter ? (
        <PeerToPeer
          userId={user?.id}
          watchChannelOrigin={watchChannelOrigin}
          watchSearchTerm={watchSearchTerm}
        />
      ) : (
        renderContent()
      )}
    </div>
  );
};
